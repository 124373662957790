<template>
<div class="subscriptioncancelled-container">
    <div class="top">
        <div style="display: flex; justify-content: center;">
            <img src="../../assets/images/subscription_cancelled.png" style="width: 60%;" />

        </div>
        <p style="text-align: center;">{{ $t('profile.subscription.cancelled.title') }}<br />
            {{ $t('profile.subscription.cancelled.caption') }}</p>
    </div>
    <div class="bottom">
        <vs-button size="large" border @click="$router.push({name: 'Profilo'})">{{ $t('profile.subscription.cancelled.backToAccount') }}</vs-button>
        <vs-button size="large" @click="$router.push({name: 'ContactUs'})">{{ $t('profile.subscription.cancelled.leaveFeedback') }}</vs-button>
    </div>

</div>
</template>

<script>
export default {
    name: "subscriptionCancelled",
    props: {},
    components: {},
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style scoped>
.top {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.bottom {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.subscriptioncancelled-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    height: calc(100% - 75px);

    justify-content: space-between;
}

/* MOBILE */
@media (max-width: 600px) {
    .subscriptioncancelled-container {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 80px!important;
height: unset!important;
        width: 100%!important;
    }

}
</style>
